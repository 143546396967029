import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import set from "lodash.set";
import { DaysOfWeek, reorder } from "mkm-avengers";
import type { Branch, WorkingTime, WorkingTimes } from "./useBranches";
dayjs.extend(isBetween);

interface BranchWorkingTime {
  [key: string]: WorkingTimes | undefined;
}

const addDayNameInWorkingTimes = (workingTimes: WorkingTimes[]) => {
  workingTimes.forEach((element, index) => {
    switch (index) {
      case 0: {
        set(element, "dayOfWeek", DaysOfWeek.MONDAY);
        break;
      }
      case 1: {
        set(element, "dayOfWeek", DaysOfWeek.TUESDAY);
        break;
      }
      case 2: {
        set(element, "dayOfWeek", DaysOfWeek.WEDNESDAY);
        break;
      }
      case 3: {
        set(element, "dayOfWeek", DaysOfWeek.THURSDAY);
        break;
      }
      case 4: {
        set(element, "dayOfWeek", DaysOfWeek.FRIDAY);
        break;
      }
      case 5: {
        set(element, "dayOfWeek", DaysOfWeek.SATURDAY);
        break;
      }
      default: {
        set(element, "dayOfWeek", DaysOfWeek.SUNDAY);
      }
    }
  });
};

const useBranchWorkingTime = (branch: Branch) => {
  const branchRef = ref(branch);

  const openStatuses = ref({
    OPEN: "open",
    OPEN_TOMORROW: "open-tomorrow",
    OPENING_SOON: "opening-soon",
    CLOSING_SOON: "closing-soon",
    CLOSED: "closed",
  });

  const nextWorkingDayWorkingTime = computed(() => {
    if (!branchRef.value?.working_times) {
      return "";
    }
    const workingTimes = branchRef.value?.working_times;
    const currentDayIndex = Object.keys(workingTimes).indexOf(dayjs().format("dddd").toLowerCase());
    const wtValues: WorkingTimes[] = Object.values(workingTimes);
    addDayNameInWorkingTimes(wtValues);
    const reorderedWtValues = reorder(wtValues, currentDayIndex); // reorder the array of wt with today wt at the start
    reorderedWtValues.shift(); // remove todays wt
    return reorderedWtValues.find((wt) => {
      // @ts-ignore
      return wt ? wt.opens_at && wt.closes_at : "";
    });
  });

  const nextWorkingDayIsTomorrow = computed(() => {
    if (nextWorkingDayWorkingTime.value === undefined) {
      return false;
    }
    // @ts-ignore
    return dayjs().add(1, "day").format("dddd") === nextWorkingDayWorkingTime?.value?.dayOfWeek;
  });

  const workingTimeToday = computed(() => {
    const dayOfWeekAsString = dayjs().format("dddd").toLowerCase();

    return (branchRef.value.working_times as BranchWorkingTime)?.[dayOfWeekAsString];
  });

  const hasWorkingTimeToday = computed(() => {
    return !!(workingTimeToday.value?.opens_at && workingTimeToday.value?.closes_at);
  });

  const openStatus = computed(() => {
    if (!hasWorkingTimeToday.value) {
      return openStatuses.value.CLOSED;
    }

    const now = dayjs();

    const closed = dayjs()
      .hour(Number.parseInt(workingTimeToday?.value?.closes_at?.split(":")[0] ?? "0"))
      .minute(Number.parseInt(workingTimeToday?.value?.closes_at?.split(":")[1] ?? "0"));

    const open = dayjs()
      .hour(Number.parseInt(workingTimeToday?.value?.opens_at?.split(":")[0] ?? "0"))
      .minute(Number.parseInt(workingTimeToday?.value?.opens_at?.split(":")[1] ?? "0"));

    if (now.isAfter(closed)) {
      return openStatuses.value.CLOSED;
    }

    if (now.isBefore(open)) {
      return openStatuses.value.OPENING_SOON;
    }

    if (now.isBetween(closed.subtract(1, "hour"), closed)) {
      return openStatuses.value.CLOSING_SOON;
    }

    if (now.isBetween(open, closed)) {
      return openStatuses.value.OPEN;
    }
  });

  const isStoreOpenText = computed(() => {
    switch (openStatus.value) {
      case openStatuses.value.CLOSED:
        if (nextWorkingDayWorkingTime.value === undefined) {
          return "Closed today";
        }
        return nextWorkingDayIsTomorrow.value
          ? `Closed. Opens tomorrow at ${(nextWorkingDayWorkingTime.value as WorkingTime)?.opens_at}`
          : `Closed. Opens ${(nextWorkingDayWorkingTime.value as BranchWorkingTime)?.dayOfWeek} ${(
              nextWorkingDayWorkingTime.value as WorkingTime
            )?.opens_at}`;
      case openStatuses.value.OPENING_SOON:
        return `Opens today at ${workingTimeToday?.value?.opens_at}`;

      case openStatuses.value.OPEN:
        return `Open today until ${workingTimeToday?.value?.closes_at}`;

      case openStatuses.value.CLOSING_SOON:
        return `Closing soon at ${workingTimeToday?.value?.closes_at}`;
    }
  });

  const openStatusStyle = computed(() => {
    switch (openStatus.value) {
      case openStatuses.value.OPEN:
        return "text-green-dark";
      case openStatuses.value.OPENING_SOON:
        return "text-mkm-yellow-darker";
      case openStatuses.value.CLOSING_SOON:
        return "text-mkm-yellow-darker";
      case openStatuses.value.CLOSED:
        return "text-red-default";
      default:
        return "text-charcoal-default";
    }
  });

  return {
    workingTimeToday,
    hasWorkingTimeToday,
    nextWorkingDayWorkingTime,
    nextWorkingDayIsTomorrow,
    openStatuses,
    openStatus,
    isStoreOpenText,
    openStatusStyle,
  };
};

export default useBranchWorkingTime;
