export enum WorkingHours {
  MO_START = 'MO_Start',
  MO_END = 'MO_End',
  TU_START = 'TU_Start',
  TU_END = 'TU_End',
  WE_START = 'WE_Start',
  WE_END = 'WE_End',
  TH_START = 'TH_Start',
  TH_END = 'TH_End',
  FR_START = 'FR_Start',
  FR_END = 'FR_End',
  SA_START = 'SA_Start',
  SA_END = 'SA_End',
  SU_START = 'SU_Start',
  SU_END = 'SU_End',
  HOLIDAY = 'Holiday',
}

export enum DaysOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
  HOLIDAY = 'Holiday',
}
